<template>
  <div v-if="!isLoading " class="w-100 event-container position-relative">
    <room-details
      v-if="step === 0"
      :item="room"
      @enterRoom="() => {this.step = 1}"
      @viewRoom="() => {this.step = 2}"
    />
    <room-events-list v-if="step === 1" :room="room" @selectedEvent="handleSelectEvent" />
    <b-card v-if="step === 2" class="room-card p-3" bg-variant="white">
      <floors-view
        v-if="room"
        :room="room"
        :selected-event="selectedEvent"
        @returnToEvent="() => {this.step = 0}"
      />
    </b-card>
  </div>
</template>
<script>
import FloorsView from './floorsView.vue';
import RoomEventsList from './eventsList.vue';
import RoomDetails from './roomInfo.vue';

export default {
  name: 'PresencialMainView',
  components: {
    RoomDetails,
    FloorsView,
    RoomEventsList,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedEvent: null,
      isLoading: false,
      isStaff: this.$store.getters.currentCollective.isStaff,
      step: 0,
    };
  },
  watch: {
    step(newValue) {
      if (newValue != 2) {
        this.selectedEvent = null;
      }
    },
  },
  methods: {
    handleSelectEvent(event) {
      this.selectedEvent = event;
      this.step = 2;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";

.event-container {
  width: 100vw;
}
.edit-icon-settings {
  position: absolute;
  right: 10px;
  top: -30px;
  cursor: pointer;
}
</style>
